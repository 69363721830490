import React from "react";
import '../../styles/semantic.min.css';
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import Patron from "../../components/about-us/Patron";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import PageHeader from "../../components/utils/PageHeader";
import {graphql, StaticQuery} from "gatsby";
import {Container, Grid, Segment} from "semantic-ui-react";
import AboutUsNavigation from "../../components/navigation/AboutUsNavigation";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import styles from "../../components/about-us/aboutUs.module.css";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import layout from "../../templates/layout.module.css";

export default () =>
    <LayoutContainer>
        <SEO title={'Schirmherr'}/>
            <StaticQuery query={patronQuery}
                         render={
                             data => {
                                 return (
                                     <>
                                         <MobileTabletContainer className={styles.mobile}>
                                             <MainNavigation/>
                                             <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                         subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                             <MobileBgWrapper>
                                                 <Container>
                                                     <Segment>
                                             <Container className={styles.container}
                                                 dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                             </Container>
                                                     </Segment>
                                                     <Segment>
                                             <Patron/>
                                                     </Segment>
                                                 </Container>
                                             </MobileBgWrapper>
                                             <Footer/>
                                         </MobileTabletContainer>
                                         <DesktopContainer className={styles.desktop}>
                                             <div className={layout.pageWrapper}>
                                             <MainNavigation/>
                                             <PageHeader title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
                                                         subHeader={data.allMarkdownRemark.edges[0].node.frontmatter.summary}/>
                                             <Container fluid>
                                             <Grid centered columns={2}>
                                                 <Grid.Column width={11}>
                                                     <Segment>
                                                         <Container className={styles.container}
                                                             dangerouslySetInnerHTML={{__html: data.allMarkdownRemark.edges[0].node.html}}>
                                                         </Container>
                                                     </Segment>
                                                         <Patron/>
                                                     <AboutUsNavigation/>
                                                 </Grid.Column>
                                                 <Grid.Column width={4}/>
                                             </Grid>
                                             </Container>
                                             <Footer/>
                                             </div>
                                         </DesktopContainer>
                                     </>
                                 )
                             }
                         }
            />
    </LayoutContainer>

const patronQuery = graphql`
query patronQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "about-us" }
            kind: { eq: "patron" }
         }
      }
      limit: 1
      ){
      edges {
        node {
            html
            frontmatter {
                title
                summary
            }
        }
      }
    }
}
`;