import React from 'react';
import {Container, Grid, Segment,} from 'semantic-ui-react';
import styles from "./aboutUs.module.css";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";
import cx from "classnames";

const Patron = () => (
    <Segment className={styles.main}>
        <StaticQuery query={patronItemsQuery}
                     render={
                         data => {
                             const Items = data.allMarkdownRemark.edges.map(i =>
                                 <Grid.Row stackable columns={2}>
                                     <Grid.Column width={6}>
                                         <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                     </Grid.Column>
                                     <Grid.Column width={10}>
                                         <p className={styles.name}>{i.node.frontmatter.title}</p>
                                         <Container className={cx(styles.details, styles.container)} dangerouslySetInnerHTML={{__html: i.node.html}}></Container>
                                     </Grid.Column>
                                 </Grid.Row>
                             );
                             return (<Grid stackable columns={2}>{Items}</Grid>)
                         }
                     }
        />
    </Segment>

);

export default Patron;

const patronItemsQuery = graphql`
query patronItemsQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "person" }
            kind: { eq: "patron" }
         }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;